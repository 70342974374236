.memory-chat-container .cs-message__content {
    background-color: #83EBC6 !important;
    
    /* height: 300px; */
  }

  .health-chat-container .cs-message__content {
    background-color: #f8a5c2 !important;
    
    /* height: 300px; */
  }

  .upgrade-chat-container .cs-message__content {
    background-color: #ffffff !important;
    
    /* height: 300px; */
  }